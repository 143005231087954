.bold-light-section-title {
  font-weight: 200;
  font-family: "Source Sans 3", sans-serif;
  text-transform: uppercase;
  color: var(--primaryGreenDark);
  font-size: 1.7rem;
}

.bold-light-section-title .bold {
  font-weight: 800;
}

@media only screen and (min-width: 370px) {
  .bold-light-section-title {
    font-size: 1.9rem;
  }
}

@media only screen and (min-width: 420px) {
  .bold-light-section-title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 480px) {
  .bold-light-section-title {
    font-size: 2.2rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bold-light-section-title {
    font-size: 2.4rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bold-light-section-title {
    font-size: 2.6rem;
  }
}
