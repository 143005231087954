@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

:root {
  --primaryGreenDark: #75af5d;
  --primaryGreenLight: #90e46b;
  --secondaryBrown: #a78136;
  --tertiaryGrey: #ccc;
  --backgroundDark: #000000ea;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

/* Global Styles for react-slick slider */
.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.slick-disabled {
  display: none !important;
}
