.single-certificate {
  display: flex !important;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
}

.single-certificate-img {
  width: 90%;
  height: auto;
}

.single-certificate-desc {
  color: #fff;
  font-weight: 10;
  text-transform: uppercase;
}
