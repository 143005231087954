#contact-section-wrapper {
  margin-top: 12vh;
  width: 100%;
  display: grid;
  place-items: center;
}

#contact-section {
  width: 90%;
}

#contact-links-subsection-wrapper {
  margin-top: 1.5rem;
}

.contact-subsection-title {
  margin-bottom: 1rem;
}

#contact-links-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.contact-links-subsection-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact-links-subsection-heading {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 0.7rem;
  color: #000;
}

.contact-links-subsection-list {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.contact-links-subsection-link,
#contact-address {
  font-size: 1.1rem;
  letter-spacing: 2px;
  color: #57617a;
  transition: color 0.2s ease-in-out;
}

.contact-links-subsection-link:hover {
  color: #2b2f3b;
  cursor: pointer;
}

#contact-address-subsection-wrapper {
  margin-bottom: 2rem;
}

#contact-address {
  color: #000;
  letter-spacing: 3px;
}

#google-map-wrapper {
  margin-top: 1rem;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

#google-map-wrapper iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (min-width: 400px) {
  .contact-links-subsection-list {
    gap: 0.6rem;
  }

  .contact-links-subsection-link {
    font-size: 1.2rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  .contact-links-subsection-wrapper {
    gap: 0.8rem;
  }

  .contact-links-subsection-heading {
    font-size: 0.8rem;
  }

  .contact-links-subsection-list {
    gap: 0.8rem;
  }

  .contact-links-subsection-link {
    font-size: 1.2rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
  .contact-links-subsection-wrapper {
    gap: 1rem;
  }

  #contact-links-wrapper {
    flex-direction: row;
  }

  .contact-links-subsection-link {
    font-size: 1.3rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #contact-section {
    width: 80%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1237px) {
  #contact-section {
    width: 70%;
  }
}
