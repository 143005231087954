#brands-section-wrapper {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#brands-section-title {
  margin: 1rem 0;
}

#brands-images-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 2rem 1rem;
  place-items: center;
  text-align: center;
  padding: 0 1.5rem;
}

.brands-single-img {
  width: 90%;
  height: auto;
}
