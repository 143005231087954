.single-category-wrapper {
  padding: 0 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 0.5rem;
}

.single-category-title {
  color: var(--secondaryBrown);
  text-transform: uppercase;
  font-size: 1.7rem;
  letter-spacing: 4px;
}

.single-category-img {
  width: 85%;
  height: auto;
}

.single-category-desc {
  text-align: justify;
  padding: 0 0.5rem;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}

@media only screen and (min-width: 321px) {
  .single-category-wrapper {
    padding: 0 0.6rem;
  }

  .single-category-title {
    font-size: 1.8rem;
  }

  .single-category-img {
    width: 90%;
  }

  .single-category-desc {
    font-size: 0.9rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .single-category-title {
    font-size: 1.9rem;
  }

  .single-category-img {
    width: 88%;
  }

  .single-category-desc {
    font-size: 1rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .single-category-img {
    width: 90%;
  }

  .single-category-desc {
    padding: 0 0.3rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .single-category-desc {
    padding: 0 0.2rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .single-category-wrapper {
    gap: 0.2rem;
  }

  .single-category-title {
    font-size: 2rem;
  }

  .single-category-desc {
    padding: 0 0.3rem;
  }
}
