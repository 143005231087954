#certifications-section-wrapper {
  width: 100%;
  height: auto;
  background-color: var(--backgroundDark);
  color: #fff;
  padding-bottom: 2.5rem;
}

#certifications-section-title-wrapper {
  width: 100%;
  display: grid;
  justify-items: center;
}

#certifications-section-title {
  padding: 1rem 0;
}

#slider-wrapper {
  padding: 0 40px;
}
