#gallery-section {
  margin: 12vh 0 2rem;
  width: 100%;
}

#gallery-technology-subsection {
  padding-top: 0.5rem;
  width: 92%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
}

#gallery-technology-images-wrapper {
  width: 100%;
  display: grid;
  gap: 0.2rem;
  place-items: center;
  align-items: stretch;
  grid-auto-flow: dense;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
}

.gallery-technology-img {
  width: 100%;
  height: auto;
}

.gallery-technology-img.big {
  grid-column: span 3;
  grid-row: span 2;
}

.gallery-technology-img.big:nth-child(2) {
  grid-column: -1/-4;
}

.gallery-technology-img.small {
  grid-column: span 2;
}

.gallery-technology-img.medium {
  grid-column: span 2;
  grid-row: span 2;
}

#gallery-international-subsection {
  width: 98%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

#gallery-international-subsection-title {
  text-align: center;
}

#gallery-international-slider-wrapper {
  padding: 0 30px;
  width: 100%;
}

#gallery-international-slider-wrapper .slick-slide > div {
  width: 100%;
  text-align: center;
}

#gallery-international-slider-wrapper .slick-slide > div > img {
  width: 100% !important;
  height: auto !important;
}

#gallery-achievement-subsection {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

#gallery-achievement-images-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

#gallery-achievement-img-award {
  width: 20%;
}

#gallery-achievement-img-receiving {
  width: 75%;
  height: auto;
  max-height: 300px;
}

@media only screen and (min-width: 400px) {
  #gallery-international-subsection {
    width: 95%;
    gap: 1rem;
  }

  #gallery-international-slider-wrapper {
    width: 98%;
  }

  #gallery-international-slider-wrapper .slick-slide > div > img {
    width: 100% !important;
  }
}

@media only screen and (min-width: 480px) {
  #gallery-technology-subsection {
    padding-top: 0.8rem;
    width: 88%;
    gap: 1rem;
  }

  #gallery-technology-images-wrapper {
    gap: 0.3rem;
  }

  #gallery-international-slider-wrapper {
    width: 95%;
  }

  #gallery-international-slider-wrapper .slick-slide > div > img {
    width: 95% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #gallery-international-subsection {
    width: 100%;
  }
  #gallery-international-slider-wrapper {
    width: 100%;
  }

  #gallery-international-slider-wrapper .slick-slide > div > img {
    width: 98% !important;
  }

  #gallery-achievement-images-wrapper {
    gap: 1rem;
  }

  #gallery-achievement-img-award {
    width: 18%;
  }

  #gallery-achievement-img-receiving {
    width: 70%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #gallery-technology-subsection {
    padding-top: 1rem;
    width: 85%;
  }

  #gallery-technology-images-wrapper {
    gap: 0.5rem;
  }

  #gallery-international-subsection {
    width: 98%;
  }

  #gallery-international-slider-wrapper .slick-slide > div > img {
    width: 95% !important;
  }

  #gallery-achievement-img-award {
    width: unset;
  }

  #gallery-achievement-img-receiving {
    width: 65%;
  }
}

@media screen and (min-width: 900px) {
  #gallery-international-slider-wrapper .slick-slide > div > img {
    width: 98% !important;
  }

  #gallery-achievement-img-receiving {
    width: 65%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #gallery-technology-subsection {
    width: 82%;
  }

  #gallery-technology-images-wrapper {
    gap: 0.7rem;
  }

  #gallery-international-subsection {
    width: 98%;
  }

  #gallery-international-slider-wrapper {
    width: 100%;
  }

  #gallery-international-slider-wrapper .slick-slide > div > img {
    width: 95% !important;
  }

  #gallery-achievement-img-receiving {
    width: 60%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #gallery-technology-subsection {
    width: 80%;
  }

  #gallery-technology-images-wrapper {
    gap: 0.8rem;
  }

  #gallery-international-subsection {
    width: 95%;
  }

  #gallery-international-slider-wrapper {
    width: 95%;
  }

  #gallery-achievement-img-receiving {
    width: 45%;
  }
}
