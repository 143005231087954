#bg-wrapper {
  color: white;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  background-image: url("../../assets/misc/rice banner.jpg");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

#bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
}

#header-wrapper {
  height: 100%;
  max-height: calc(100vh - 14vh);
  padding: 0 30px;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  z-index: 1;
}

#header-txt-grp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
}

#header-title {
  font-size: 1.2rem;
  margin-bottom: 0;
}

#header-title-name {
  font-size: 1.7rem;
  color: var(--primaryGreenLight);
}

#header-text {
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  font-weight: 500;
}

#header-text-name {
  color: #000;
  font-weight: bold;
}

#header-btn-grp {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (min-width: 321px) {
  #header-wrapper {
    padding: 0 40px;
  }

  #header-txt-grp {
    gap: 1.1rem;
  }

  #header-title {
    font-size: 1.1rem;
  }

  #header-title-name {
    font-size: 1.7rem;
  }

  #header-text {
    font-size: 0.8rem;
    letter-spacing: 0.6px;
  }
}

@media only screen and (min-width: 480px) {
  #header-wrapper {
    padding: 0 50px;
  }

  #header-title {
    font-size: 1.2rem;
  }

  #header-title-name {
    font-size: 1.9rem;
  }

  #header-text {
    font-size: 0.85rem;
    letter-spacing: 0.7px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #header-wrapper {
    max-height: calc(100vh - 15vh);
    padding: 0 55px;
  }

  #header-title {
    font-size: 1.25rem;
  }

  #header-title-name {
    font-size: 2rem;
  }

  #header-text {
    font-size: 0.9rem;
    letter-spacing: 0.8px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #header-wrapper {
    padding: 0 60px;
  }

  #header-title {
    font-size: 1.3rem;
  }

  #header-title-name {
    font-size: 2.2rem;
  }

  #header-text {
    font-size: 1rem;
    letter-spacing: 0.9px;
  }
}

@media screen and (min-width: 900px) {
  #header-wrapper {
    padding: 0 70px;
  }

  #header-title {
    font-size: 1.3rem;
  }

  #header-title-name {
    font-size: 2.3rem;
    letter-spacing: 3px;
  }

  #header-text {
    font-size: 1.1rem;
    letter-spacing: 1px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #header-wrapper {
    width: 100%;
    max-height: calc(100vh - 20vh);
    padding: 0 95px;
    place-items: center;
    grid-template-columns: 63% 31%;
    gap: 3rem;
    align-content: center;
  }

  #header-txt-grp {
    gap: 1.5rem;
  }

  #header-title-name {
    font-size: 2.4rem;
  }

  #header-text {
    font-size: 1.15rem;
  }

  #header-btn-grp {
    gap: 2rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #header-wrapper {
    padding: 0 100px;
  }

  #header-title {
    letter-spacing: 1px;
  }

  #header-title-name {
    letter-spacing: 4px;
  }

  #header-text {
    font-size: 1.15rem;
  }

  #header-btn-grp {
    gap: 2rem;
  }
}
