#about-founders-subsection {
  width: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 2rem;
}

#founders-subsection-title {
  margin: 1.5rem 0 1rem 0;
}

#founders-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.single-founder-wrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
}

.single-founder-wrapper.right {
  flex-direction: column;
}

.single-founder-img-wrapper {
  width: 60%;
  display: grid;
  place-items: center;
}

.single-founder-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.single-founder-info {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.right .single-founder-info {
  text-align: left;
}

.single-founder-msg {
  font-style: italic;
  font-size: 1rem;
  background-color: #ccc;
  padding: 0.8rem;
  border-radius: 3px;
}

.single-founder-info-name {
  text-transform: capitalize;
  font-size: 1.3rem;
  color: var(--primaryGreenDark);
  letter-spacing: 1px;
}

.single-founder-name-wrapper {
  text-align: center;
}

#single-founder-info-name-late {
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: normal;
}

.single-founder-info-role {
  text-transform: uppercase;
  font-style: italic;
  font-weight: 100;
  letter-spacing: 2px;
}

@media only screen and (min-width: 480px) {
  .single-founder-wrapper {
    width: 90%;
  }

  .single-founder-img-wrapper {
    width: 50%;
  }

  .single-founder-msg {
    font-size: 1rem;
  }

  .single-founder-info-name {
    font-size: 1.4rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .single-founder-wrapper {
    width: 85%;
  }

  .single-founder-info-name {
    letter-spacing: 2px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .single-founder-wrapper {
    width: 95%;
    flex-direction: row;
    gap: 1rem;
  }

  .single-founder-wrapper.right {
    flex-direction: row-reverse;
  }

  .single-founder-img-wrapper {
    width: 40%;
  }

  .single-founder-info {
    padding: 0.8rem;
    width: 70%;
    flex-direction: column;
    background-color: #ccc;
    border-radius: 3px;
  }

  .right .single-founder-info {
    text-align: right;
  }

  .single-founder-name-wrapper {
    text-align: left;
  }

  .right .single-founder-name-wrapper {
    text-align: right;
  }

  .single-founder-msg {
    font-size: 1rem;
    padding: 0 0.3rem;
  }

  .single-founder-info-name {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 900px) {
  .single-founder-wrapper {
    width: 90%;
    align-items: stretch;
  }

  .single-founder-msg {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .single-founder-info-name {
    font-size: 1.6rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .single-founder-wrapper {
    width: 90%;
  }

  .single-founder-wrapper.right {
    flex-direction: row-reverse;
  }

  .single-founder-img-wrapper {
    width: 30%;
  }

  .single-founder-img {
    width: 95%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .single-founder-wrapper {
    width: 80%;
  }

  .single-founder-img {
    width: 85%;
  }

  .single-founder-msg {
    font-size: 1.05rem;
  }
}
