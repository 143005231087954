#nav-wrapper {
  width: 100%;
  color: #fff;
  padding: 15px 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: transparent;
  transition: none;
  z-index: 5;
}

#nav-wrapper.scrolled {
  background-color: var(--backgroundDark);
  z-index: 5;
  height: 10%;
  transition: background-color 1s ease-in-out;
}

.nav-logo-wrapper {
  z-index: 3;
}

#nav-logo {
  width: 75px;
  height: 45px;
}

#nav-links-wrapper {
  display: none;
}

.nav-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 3;
}

.nav-icon-wrapper:hover {
  cursor: pointer;
}

.nav-icon-text {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 0rem;
}

.nav-icon {
  width: 20px;
  height: 30px;
  color: #fff;
}

@media only screen and (min-width: 321px) {
  #nav-wrapper {
    padding: 18px 40px;
  }

  .nav-icon-text {
    letter-spacing: 2px;
  }

  .nav-icon {
    width: 20px;
    height: 30px;
    color: #fff;
  }
}

@media only screen and (min-width: 480px) {
  #nav-wrapper {
    padding: 20px 50px;
  }

  #nav-logo {
    width: 80px;
    height: 48px;
  }

  .nav-icon-text {
    font-size: 1rem;
    letter-spacing: 3px;
  }

  .nav-icon {
    width: 24px;
    height: 35px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #nav-wrapper {
    padding: 22px 60px;
  }

  #nav-logo {
    width: 85px;
    height: 52px;
  }

  .scrolled #nav-logo {
    width: 82px;
    height: 48px;
  }

  .nav-icon-wrapper {
    gap: 15px;
  }

  .nav-icon {
    width: 25px;
    height: 36px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #nav-wrapper {
    padding: 30px 60px;
  }

  #nav-logo {
    width: 90px;
    height: 52px;
  }

  #nav-links-wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .nav-single-link {
    color: var(--tertiaryGrey);
    transition: color 0.3s ease-in-out;
  }

  .nav-single-link > .active {
    color: #fff;
  }

  .nav-single-link:hover {
    cursor: pointer;
    color: var(--primaryGreenDark);
  }

  .nav-icon-wrapper {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  #nav-links-wrapper {
    gap: 2.2rem;
    font-size: 0.81rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #nav-wrapper {
    padding: 30px 100px;
    justify-content: flex-start;
    gap: 3rem;
  }

  #nav-wrapper.scrolled {
    justify-content: space-between;
  }

  #nav-logo {
    width: 95px;
    height: 55px;
  }

  #nav-links-wrapper {
    gap: 2rem;
    font-size: 0.83rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #nav-wrapper {
    padding: 25px 100px;
    gap: 4rem;
  }

  #nav-wrapper.scrolled {
    padding: 35px 100px;
  }

  #nav-logo {
    width: 120px;
    height: 65px;
  }

  .scrolled #nav-logo {
    width: 110px;
    height: 55px;
  }

  #nav-links-wrapper {
    padding-right: 4rem;
    gap: 2.5rem;
    font-size: 0.84rem;
  }
}
