.nav-modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  color: var(--tertiaryGrey);
  transition: transform 0.6s ease-in-out;
  transform: translateY(-100%);
}

.nav-modal-display {
  transform: translateY(0%);
}

.nav-modal {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 58%;
}

.nav-modal-list {
  max-width: 100%;
  max-height: 100%;
  padding: 12vh 40px 0 40px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-transform: uppercase;
  letter-spacing: 8px;
}

.nav-modal-link {
  font-size: 0.9rem;
  width: 100%;
  border-bottom: #ffffff1f solid 1px;
  padding-bottom: 10px;
  transition: color 0.3s ease-in-out;
}

.nav-modal-link > .active {
  color: #fff;
}

.nav-modal-link:first-child {
  padding-top: 10px;
  border-top: #ffffff1f solid 1px;
}

.nav-modal-link:hover {
  cursor: pointer;
  color: var(--primaryGreenDark);
}

@media only screen and (min-width: 480px) {
  .nav-modal-list {
    padding-top: 14vh;
  }
}

@media only screen and (min-width: 600px) {
  .nav-modal-list {
    padding-top: 16vh;
  }
}
