.arrowIcon {
  color: #fff !important;
  font-size: 1.5rem;
  background-color: transparent;
  width: 20px;
  height: 30px;
  transition: all 0.4s ease-in-out;
}

.arrowIcon.dark {
  color: #000 !important;
}

.arrowIcon:hover {
  cursor: pointer;
  background-color: var(--primaryGreenDark);
}

.arrowIcon.dark:hover {
  color: #fff !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .arrowIcon {
    font-size: 2rem;
    width: 25px;
    height: 35px;
  }
}
