#product-categories-section-wrapper {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#product-categories-section-title {
  margin: 1rem 0 0.5rem 0;
}

#product-categories-wrapper {
  max-width: 100%;
  display: grid;
  text-align: center;
  gap: 2rem 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 2rem;
}
