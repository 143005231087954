#footer-section-wrapper {
  border-bottom: 6px solid var(--primaryGreenDark);
  max-width: 100%;
}

#footer-section {
  width: 100%;
  background-color: var(--backgroundDark);
  color: #ffff;
  padding: 1rem 0.5rem;
}

#footer-links-section {
  width: 100%;
  display: grid;
  gap: 3.5rem 1rem;
  grid-template-columns: repeat(auto-fit, minmax(291px, 1fr));
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}

.footer-links-subsection-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-links-subsection-heading {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 0.7rem;
  color: var(--primaryGreenDark);
}

.footer-links-subsection-list {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.footer-links-subsection-link {
  font-size: 1.1rem;
  letter-spacing: 2px;
}

#footer-logo-section {
  width: 100%;
  border-top: 1px solid #ffffff1f;
  padding: 1.5rem 0.8rem 0rem 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

#footer-logo {
  width: 50%;
  height: auto;
}

#footer-copyrights {
  text-align: center;
  letter-spacing: 1px;
}

#footer-copyrights-company {
  color: var(--primaryGreenDark);
  font-weight: 700;
  letter-spacing: 1px;
}

@media screen and (min-width: 400px) {
  #footer-section {
    padding: 1.5rem 1rem;
  }

  .footer-links-subsection-list {
    gap: 0.6rem;
  }

  .footer-links-subsection-link {
    font-size: 1.2rem;
  }

  #footer-logo {
    width: 40%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  #footer-section {
    padding: 1.5rem;
  }

  .footer-links-subsection-heading {
    font-size: 0.8rem;
  }

  .footer-links-subsection-list {
    gap: 0.8rem;
  }

  .footer-links-subsection-link {
    font-size: 1.2rem;
  }

  #footer-logo {
    width: 40%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
  .footer-links-subsection-link {
    font-size: 1.3rem;
  }

  #footer-logo {
    width: 30%;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  #footer-section {
    display: flex;
    flex-direction: row-reverse;
    gap: 3rem;
    align-items: center;
  }

  #footer-links-section {
    width: 70%;
    margin-bottom: 0;
    color: var(--tertiaryGrey);
  }

  .footer-links-subsection-link {
    transition: all 0.3s ease-in-out;
  }

  .footer-links-subsection-list li:hover {
    color: #fff;
    cursor: pointer;
  }

  #footer-logo-section {
    width: 30%;
    border-top: none;
    border-right: 1px solid #ffffff1f;
  }

  #footer-logo {
    width: 80%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1237px) {
  #footer-links-section {
    display: flex;
    justify-content: space-around;
  }
  #footer-logo {
    width: 60%;
  }
}
